import request from '@/util/request'

//基础列表
export function list(data) {
    return request({
        url: '/hui-yuan-fen-xi/list',
        method: 'post',
        data
    })
}

export function list2(data) {
    return request({
        url: '/hui-yuan-fen-xi/list2',
        method: 'post',
        data
    })
}

export function list3(data) {
    return request({
        url: '/hui-yuan-fen-xi/list3',
        method: 'post',
        data
    })
}
export function listSr(data) {
    return request({
        url: '/hui-yuan-fen-xi/listSr',
        method: 'post',
        data
    })
}

//添加保存
export function add(data) {
    return request({
        url: '/hui-yuan-fen-xi/add',
        method: 'post',
        data
    })
}

//获取编辑页数据
export function getUpdate(id) {
    return request({
        url: `/hui-yuan-fen-xi/get-update/${id}`,
        method: 'get',
    })
}

//获取详情页数据
export function getDetail(id) {
    return request({
        url: `/hui-yuan-fen-xi/get-detail/${id}`,
        method: 'get',
    })
}

//编辑保存
export function update(data) {
    return request({
        url: '/hui-yuan-fen-xi/update',
        method: 'post',
        data
    })
}

//删除
export function del(id) {
    return request({
        url: `/hui-yuan-fen-xi/delete/${id}`,
        method: 'post',
    })
}

//批量删除
export function deleteBatch(data) {
    return request({
        url: '/hui-yuan-fen-xi/delete-batch',
        method: 'post',
        data
    })
}

//独立查询
export function query(data) {
    return request({
        url: '/hui-yuan-fen-xi/query',
        method: 'post',
        data
    })
}

export function hytj() {
    return request({
        url: '/hui-yuan-xx/hyTongJi',
        method: 'post',
    })
}

/*
列表路由
{
    path: 'hui-yuan-fen-xi-list',
    component: () => import('@/view/hygl/hyfx/HuiYuanFenXiList'),
    name: 'HuiYuanFenXiList',
    meta: {title: '会员分析', isTag: true, isMenu: true, searchable: true}
}

独立查询路由
{
    path: 'hui-yuan-fen-xi-query',
    component: () => import('@/view/hygl/hyfx/HuiYuanFenXiQuery'),
    name: 'HuiYuanFenXiQuery',
    meta: {title: '会员分析查询', isTag: true, isMenu: true, searchable: true}
}

提醒列表
{
    path: 'hui-yuan-fen-xi-remind-list',
    component: () => import('@/view/hygl/hyfx/HuiYuanFenXiRemindList'),
    name: 'HuiYuanFenXiRemindList',
    meta: {title: '会员分析提醒', isTag: true, isMenu: true, searchable: true}
}

菜单识别:基础相关-list为入口
[
{"name":"会员分析基础列表","methodUrl":"/hui-yuan-fen-xi/list","operateType":"search","sort":1,"functionClassification":"0","_key":"1"},
{"name":"会员分析添加保存","methodUrl":"/hui-yuan-fen-xi/add","operateType":"add","sort":2,"functionClassification":"0","_key":"2"},
{"name":"会员分析获取编辑数据","methodUrl":"/hui-yuan-fen-xi/get-update/{id}","operateType":"search","sort":3,"functionClassification":"0","_key":"3"},
{"name":"会员分析获取详情数据","methodUrl":"/hui-yuan-fen-xi/get-detail/{id}","operateType":"search","sort":4,"functionClassification":"0","_key":"4"},
{"name":"会员分析编辑保存","methodUrl":"/hui-yuan-fen-xi/update","operateType":"edit","sort":5,"functionClassification":"0","_key":"5"},
{"name":"会员分析删除","methodUrl":"/hui-yuan-fen-xi/delete/{id}","operateType":"delete","sort":6,"functionClassification":"0","_key":"6"},
{"name":"会员分析批量删除","methodUrl":"/hui-yuan-fen-xi/delete-batch","operateType":"delete","sort":7,"functionClassification":"0","_key":"7"}
]
菜单识别:独立查询-query为入口
[
{"name":"会员分析独立查询","methodUrl":"/hui-yuan-fen-xi/query","operateType":"search","sort":11,"functionClassification":"0","_key":"11"}
]
*/
