<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
<!--        <div class="x-page-search">-->
<!--            <div class="x-search-item">-->
<!--                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>-->
<!--                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>-->
<!--            </div>-->
<!--        </div>        &lt;!&ndash; 批量按钮区 &ndash;&gt;-->
<!--        <div class="x-page-btn">-->
<!--            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>-->
<!--            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">-->
<!--                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>-->
<!--            </el-popconfirm>-->
<!--        </div>-->
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="会员活跃度统计" name="first" :lazy="true">

<!--                <div   class="echart" id="mychart" :style="myChartStyle"></div>-->
                <div  v-if="activeName==='first'"  class="echart" id="mychart" :style="myChartStyle"></div>

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data"  border
                          @selection-change="handleSelectionChange">
                    <el-table-column width="50" label="序号" type="index" />
                    <el-table-column  prop="xingMing" label="姓名" />
                    <el-table-column  prop="shouJiHao" label="手机号" />
                    <el-table-column  prop="huiYuanLX" label="会员类型" >
                        <x-dict-show slot-scope="{row}" :code="row.huiYuanLX" dictType="T_HUI_YUAN_LX" />
                    </el-table-column>
                    <el-table-column  prop="huiYuanDJ" label="会员等级" />
                    <el-table-column  prop="zuiHouXiaoFeiSJ" label="最后活跃时间" />
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </el-tab-pane>

            <el-tab-pane label="会员睡眠度统计" name="second">
                <div  v-if="activeName==='second'" class="echart" id="mychart2" :style="myChartStyle"></div>
<!--                <div  class="echart" id="mychart2" :style="myChartStyle"></div>-->

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data"  border
                          @selection-change="handleSelectionChange">
                    <el-table-column width="50" label="序号" type="index" />
                    <el-table-column  prop="xingMing" label="姓名" />
                    <el-table-column  prop="shouJiHao" label="手机号" />
                    <el-table-column  prop="huiYuanLX" label="会员类型" >
                        <x-dict-show slot-scope="{row}" :code="row.huiYuanLX" dictType="T_HUI_YUAN_LX" />
                    </el-table-column>
                    <el-table-column  prop="huiYuanDJ" label="会员等级" />
                    <el-table-column  prop="zuiHouXiaoFeiSJ" label="最后活跃时间" />
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </el-tab-pane>

            <el-tab-pane label="会员生日统计" name="thrid">
                <div v-if="activeName==='thrid'" class="echart" id="mychart3" :style="myChartStyle"></div>

                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data"  border
                          @selection-change="handleSelectionChange">
                    <el-table-column width="50" label="序号" type="index" />
                    <el-table-column  prop="xingMing" label="姓名" />
                    <el-table-column  prop="shouJiHao" label="手机号" />
                    <el-table-column  prop="huiYuanLX" label="会员类型" >
                        <x-dict-show slot-scope="{row}" :code="row.huiYuanLX" dictType="T_HUI_YUAN_LX" />
                    </el-table-column>
                    <el-table-column  prop="huiYuanShengRi" label="会员生日" />
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </el-tab-pane>
        </el-tabs>

        <!-- 编辑页区域 -->
<!--        <edit ref="edit" :editor="editor" @submitted="refresh"/>-->
<!--        <detail :detail="detail"/>-->
    </div>
</template>
<script>
    import XTableBase from "@/components/x/XTableBase";
    import * as service from "@/service/hygl/HuiYuanFenXi";
    import * as echarts from "echarts";

    export default {
        name: "HuiYuanTongJi",
        mixins: [XTableBase],
        components: {},
        data() {
            this.refreshService = service.list;
            this.service = service;
            this.searchNoClear = {current: 1, size: 10};
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            return {
                activeName: 'first',
                active:true,
                search: {
                    index: '',
                },
                myChart: {},
                myChart2: {},
                myChart3: {},
                pieData: [],
                pieData2: [],
                pieData3: [],
                pieName: [],
                pieName2: [],
                pieName3: [],
                myChartStyle: { float: "center", width: "100%", height: "400px" }, //图表样式

                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                },
            }
        },
        computed: {
            isComponent() {
                this.$store.commit('user/setTerminalAlarmStaActive',this.activeName);
            }
        },
        mounted() {
            this.initDate(); //数据初始化
            // this.initEcharts();
        },
        watch:{
            activeName: {
                handler(){
                    console.log(this.activeName)
                    this.$nextTick(()=>{
                        if (this.activeName ==='first'){
                            this.initEcharts()
                        } else if (this.activeName ==='second') {
                            this.initEcharts2()
                        }else {
                            this.initEcharts3()
                        }

                        this.loading=false;
                    })
                }
            }
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            },
            myrefresh(){
                if (this.activeName==="first"){
                    service.list2(Object.assign(this.search, this.searchNoClear)).then(res=>{
                        console.log(res)
                        this.table.data=res.data.records
                        this.table.total = res.data.total || 0;
                    })
                }else if(this.activeName==="second"){
                    service.list3(Object.assign(this.search, this.searchNoClear)).then(res=>{
                        console.log(res)
                        this.table.data=res.data.records
                        this.table.total = res.data.total || 0;
                    })
                }else if(this.activeName==="thrid"){
                    service.listSr(Object.assign(this.search, this.searchNoClear)).then(res=>{
                        console.log(res)
                        this.table.data=res.data.records
                        this.table.total = res.data.total || 0;
                    })
                }
            },
            initDate() {
                service.hytj().then(res=>{
                    console.log(res.data)
                    this.pieData=res.data.huoYueTJ;
                    this.pieData2=res.data.shuiMianTJ;
                    this.pieData3=res.data.shengRiTJ;
                    console.log(this.pieData)
                    console.log("这是睡眠统计:",this.pieData2)
                    console.log('piedata的长度'+this.pieData.length)
                    for (let i = 0; i < this.pieData.length; i++) {
                        this.pieName[i] = this.pieData[i].name;
                    }
                    for (let i = 0; i < this.pieData2.length; i++) {
                        this.pieName2[i] = this.pieData2[i].name;
                    }
                    for (let i = 0; i < this.pieData3.length; i++) {
                        this.pieName3[i] = this.pieData3[i].name;
                    }
                    this.$nextTick(()=>{
                        if (this.activeName=='first'){
                            this.initEcharts()
                        } else if (this.activeName ==='second') {
                            this.initEcharts2()
                        }else {
                            this.initEcharts3()
                        }
                        this.loading=false;
                    })

                })
            },
            initEcharts() {
                // 饼图
                const option = {
                    legend: {
                        // 图例
                        data: this.pieName,
                        right: "10%",
                        top: "30%",
                        orient: "vertical"
                    },
                    title: {
                        // 设置饼图标题，位置设为顶部居中
                        text: "会员活跃度统计",
                        top: "0%",
                        left: "center"
                    },
                    series: [
                        {
                            type: "pie",
                            label: {
                                show: true,
                                formatter: "{b} : {c} ({d}%)" // b代表名称，c代表对应值，d代表百分比
                            },
                            radius: "70%", //饼图半径
                            data: this.pieData,
                            selectedMode: 'single',
                        }
                    ]
                };
                var optionFree = {
                    xAxis: {},
                    yAxis: {},
                    series: [
                        {
                            data: this.seriesData,
                            type: "line",
                            smooth: true
                        }
                    ]
                };
                this.myChart = echarts.init(document.getElementById("mychart"));
                // this.myChart2 = echarts.init(document.getElementById("mychart2"));

                // 使用刚指定的配置项和数据显示图表。
                this.myChart.setOption(option);
                // this.myChart2.setOption(option2);
                // 处理点击事件
                var _this=this
                this.myChart.on('click', function(params) {
                    console.log(params)
                    _this.search.index=params.dataIndex
                    _this.searchNoClear.current=1
                    _this.myrefresh()
                    // console.log(_this.search)
                    // service.list2(Object.assign({index:params.dataIndex}, {current: 1, size: 10})).then(res=>{
                    //     console.log(res)
                    //     _this.table.data=res.data.records
                    //     _this.table.total = res.data.total || 0;
                    // })

                });

                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    this.myChart.resize();
                    // this.myChart2.resize();
                });

            },
            initEcharts2() {
                const option2 = {
                    legend: {
                        // 图例
                        data: this.pieName2,
                        right: "10%",
                        top: "30%",
                        orient: "vertical"
                    },
                    title: {
                        // 设置饼图标题，位置设为顶部居中
                        text: "会员睡眠统计",
                        top: "0%",
                        left: "center"
                    },
                    series: [
                        {
                            type: "pie",
                            label: {
                                show: true,
                                formatter: "{b} : {c} ({d}%)" // b代表名称，c代表对应值，d代表百分比
                            },
                            radius: "70%", //饼图半径
                            data: this.pieData2
                        }
                    ]
                };
                var optionFree = {
                    xAxis: {},
                    yAxis: {},
                    series: [
                        {
                            data: this.seriesData,
                            type: "line",
                            smooth: true
                        }
                    ]
                };
                this.myChart2 = echarts.init(document.getElementById("mychart2"));

                this.myChart2.setOption(option2);
                // 处理点击事件
                var _this=this
                this.myChart2.on('click', function(params) {
                    console.log(params)
                    _this.search.index=params.dataIndex
                    _this.searchNoClear.current=1
                    console.log(_this.search)
                    _this.myrefresh()
                    // service.list3(Object.assign(_this.search, _this.searchNoClear)).then(res=>{
                    //     console.log(res)
                    //     _this.table.data=res.data.records
                    //     _this.table.total = res.data.total || 0;
                    // })

                });

                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    this.myChart2.resize();
                });

            },
            initEcharts3() {
                const option3 = {
                    legend: {
                        // 图例
                        data: this.pieName3,
                        right: "10%",
                        top: "30%",
                        orient: "vertical"
                    },
                    title: {
                        // 设置饼图标题，位置设为顶部居中
                        text: "会员生日统计",
                        top: "0%",
                        left: "center"
                    },
                    series: [
                        {
                            type: "pie",
                            label: {
                                show: true,
                                formatter: "{b} : {c} ({d}%)" // b代表名称，c代表对应值，d代表百分比
                            },
                            radius: "70%", //饼图半径
                            data: this.pieData3
                        }
                    ]
                };
                var optionFree = {
                    xAxis: {},
                    yAxis: {},
                    series: [
                        {
                            data: this.seriesData,
                            type: "line",
                            smooth: true
                        }
                    ]
                };
                this.myChart3 = echarts.init(document.getElementById("mychart3"));

                this.myChart3.setOption(option3);
                // 处理点击事件
                var _this=this
                this.myChart3.on('click', function(params) {
                    console.log(params)
                    _this.search.index=params.dataIndex
                    _this.searchNoClear.current=1
                    console.log(_this.search)
                    _this.myrefresh()
                    // service.list3(Object.assign(_this.search, _this.searchNoClear)).then(res=>{
                    //     console.log(res)
                    //     _this.table.data=res.data.records
                    //     _this.table.total = res.data.total || 0;
                    // })

                });

                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    this.myChart3.resize();
                });

            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                if (this.search.index==null || this.search.index===''){
                    this.refresh()
                }
                else {
                    this.myrefresh();
                }

            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                if (this.search.index==null || this.search.index===''){
                    this.refresh()
                } else {
                    this.myrefresh();
                }
            },
        }
    }
</script>

<style scoped>
</style>
